import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Cursor from './components/cursor/Cursor';
import AuthContextProvider from './utils/authContext';
import LangContextProvider, { LangContext } from './utils/langContext';
import MenuContextProvider from './utils/menuContext';
import MouseContextProvider from './utils/mouseContext';
import ThemeContextProvider from './utils/themeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MouseContextProvider>
    <MenuContextProvider>
      <ThemeContextProvider>
        <AuthContextProvider>
          <LangContextProvider>
            <App />
          </LangContextProvider>
        </AuthContextProvider>
      </ThemeContextProvider>
    </MenuContextProvider>
  </MouseContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
