import BaseLayout from "../../components/layout/BaseLayout";
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";

const Confidential = () => {

    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <title>Политика конфиденциальности - MatreshkaVPN</title>
            </Helmet>
            <BaseLayout light={true}>
                <div className="confidential-container">
                    <p className="confidential-content">
                       {parse(t("policy"))}
                    </p>
                </div>
            </BaseLayout>
        </>
    );
}

export default Confidential;