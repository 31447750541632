import { Canvas, useThree } from '@react-three/fiber';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BackThreeLines from '../../components/back-three-lines/BackThreeLines';
import BaseLayout from '../../components/layout/BaseLayout';
import * as THREE from 'three'
import Globe from '../../components/globe/Globe';
import SphereAnimated from '../../components/sphere-animated/SphereAnimated';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const About = () => {

    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <title>{parse(t("about_page_title"))}</title>
            </Helmet>
            <div className="about-page-wrapper">
                <BaseLayout light={true}>
                    <BackThreeLines style={{
                        zIndex: 1
                    }} />
                    <div className="about-page-content-wrapper">
                        <div className="about-page-content-container">
                            <p className="about-page-content-header">
                                {parse(t("about_page_content_header"))}
                            </p>

                            <div className="about-page-description-container">
                                <p className="about-page-description-header">
                                    {parse(t("about_page_description_header_1"))}
                                </p>

                                <p className="about-page-description">
                                    {parse(t("about_page_description_p_1"))}
                                </p>

                                <p className="about-page-description">
                                    {parse(t("about_page_description_p_2"))}
                                </p>

                                <Canvas style={{
                                    height: 400
                                }} className='about-page-description-image'>
                                    <Globe />
                                </Canvas>

                                {/* <Canvas style={{
                                    height: 300
                                }} className='about-page-description-image'>
                                    <pointLight color={"#fff"} position={[50, 100, 50]} />
                                    <SphereAnimated />
                                </Canvas> */}

                                {/* <p className="about-page-description-subheader">
                                    WHAT WE DO
                                </p> */}

                                <p className="about-page-description">
                                    {parse(t("about_page_description_p_4"))}
                                </p>

                                <div className="about-page-description-quote-container">
                                    <p className="about-page-description-quote">
                                        {parse(t("about_page_description_quote"))}
                                    </p>
                                </div>

                                <p className="about-page-description-subheader">
                                    {parse(t("about_page_description_header_2"))}
                                </p>

                                <p className="about-page-description">
                                    {parse(t("about_page_description_p_5"))}
                                </p>

                                <p className="about-page-description-subheader">
                                    {parse(t("about_page_description_header_3"))}
                                </p>

                                <p className="about-page-description">
                                    {parse(t("about_page_description_p_6"))}
                                </p>

                                <p className="about-page-description-subheader">
                                    {parse(t("about_page_description_header_4"))}
                                </p>
                                <div className="about-page-roadmap-container">
                                    <div className="about-page-roadmap-item-container">
                                        <div className="about-page-roadmap-state-container completed">
                                            <span className="about-page-roadmap-state-indicator" />

                                            <p className="about-page-roadmap-state-text">
                                                {parse(t("about_page_roadmap_state_1"))}
                                            </p>
                                        </div>

                                        <p className="about-page-roadmap-header">
                                            {parse(t("about_page_roadmap_name_1"))}
                                        </p>

                                        <div className="about-page-roadmap-description-container">
                                            <p className="about-page-roadmap-description-row">
                                                {parse(t("about_page_roadmap_1_row_1"))}
                                            </p>
                                            <p className="about-page-roadmap-description-row">
                                                {parse(t("about_page_roadmap_1_row_2"))}
                                            </p>
                                            <p className="about-page-roadmap-description-row">
                                                {parse(t("about_page_roadmap_1_row_3"))}
                                            </p>
                                            <p className="about-page-roadmap-description-row">
                                                {parse(t("about_page_roadmap_1_row_4"))}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="about-page-roadmap-item-container">
                                        <div className="about-page-roadmap-state-container completed">
                                            <span className="about-page-roadmap-state-indicator" />

                                            <p className="about-page-roadmap-state-text">
                                                {parse(t("about_page_roadmap_state_2"))}
                                            </p>
                                        </div>

                                        <p className="about-page-roadmap-header">
                                            {parse(t("about_page_roadmap_name_2"))}
                                        </p>

                                        <div className="about-page-roadmap-description-container">
                                            <p className="about-page-roadmap-description-row">
                                                {parse(t("about_page_roadmap_2_row_1"))}
                                            </p>
                                            <p className="about-page-roadmap-description-row">
                                                {parse(t("about_page_roadmap_2_row_2"))}
                                            </p>
                                            <p className="about-page-roadmap-description-row">
                                                {parse(t("about_page_roadmap_2_row_3"))}
                                            </p>
                                            <p className="about-page-roadmap-description-row">
                                                {parse(t("about_page_roadmap_2_row_4"))}
                                            </p>

                                            <p className="about-page-roadmap-description-row">
                                                {parse(t("about_page_roadmap_2_row_5"))}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="about-page-roadmap-item-container">
                                        <div className="about-page-roadmap-state-container process">
                                            <span className="about-page-roadmap-state-indicator" />

                                            <p className="about-page-roadmap-state-text">
                                                {parse(t("about_page_roadmap_state_3"))}
                                            </p>
                                        </div>

                                        <p className="about-page-roadmap-header">
                                            {parse(t("about_page_roadmap_name_3"))}
                                        </p>

                                        <div className="about-page-roadmap-description-container">
                                            <p className="about-page-roadmap-description-row">
                                                {parse(t("about_page_roadmap_3_row_1"))}
                                            </p>
                                            <p className="about-page-roadmap-description-row">
                                                {parse(t("about_page_roadmap_3_row_2"))}
                                            </p>
                                            <p className="about-page-roadmap-description-row">
                                                {parse(t("about_page_roadmap_3_row_3"))}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BaseLayout>
            </div>
        </>
    );
}

export default About;