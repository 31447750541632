import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "./i18n";

export const LANGS = [
    {
        title: "Русский",
        code: "ru-RU",
        backCode: "RU"
    },
    {
        title: "English",
        code: "en-US",
        backCode: "EN"
    },
    {
        title: "中国人",
        code: "zh-CN",
        backCode: "CH"
    },
]

export const LangContext = createContext({
    nowLang: null,
    langs: [],
    changeLang: (code) => {}
})
const LangContextProvider = (props) => {
    const [lang, setLang] = useState(null)

    useEffect(() => {
        if(!localStorage.getItem("i18nextLng")) {
            i18n.changeLanguage("en")
        }

        setLang(i18n.language)
    }, [])

    const changeLang = (code) => {
        i18n.changeLanguage(code)

        setLang(code)
    }

    return (
        <LangContext.Provider
            value={{
                langs: LANGS,
                nowLang: lang,
                changeLang
            }}
        >
            {props.children}
        </LangContext.Provider>
    );
};
export default LangContextProvider;