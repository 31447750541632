import { useContext, useState } from "react";
import { MenuContext } from "../../utils/menuContext";
import { MouseContext } from "../../utils/mouseContext";
import { AiFillAndroid, AiFillApple, AiFillWindows, AiOutlineApple } from 'react-icons/ai'

import CloseBlack from '../../assets/images/close_black.svg'
import Close from '../../assets/images/close.svg'
import { useTranslation } from "react-i18next";

const PhoneMenu = ({ light = false }) => {
    const { t } = useTranslation()
    const { show, toggle } = useContext(MenuContext)
    const [showPlatforms, setShowPlatforms] = useState(false)

    const { cursorChangeHandler } = useContext(MouseContext)

    return (
        <div className={`phone-menu-container ${light && "light"} ${show && "show"}`}>
            <img onMouseEnter={() => cursorChangeHandler("hover")}
                onMouseLeave={() => cursorChangeHandler("")} onClick={toggle} width={38} height={38} src={light ? CloseBlack : Close} className="phone-menu-close-icon" />

            <a onMouseEnter={() => cursorChangeHandler("hover")}
                onMouseLeave={() => cursorChangeHandler("")} href="/" className="phone-menu-route">
                 {t("navigation_route_1")}
            </a>

            <a onClick={() => setShowPlatforms(!showPlatforms)} href="#" className="phone-menu-route">
            {t("navigation_route_2")}

                <div className={`phone-menu-platforms-container ${showPlatforms ? "show" : '"'}`}>
                    <a href="/download/android" className="phone-menu-platform-item">
                        <AiFillAndroid style={{ marginRight: 10 }} size={44} /> Android
                    </a>

                    <a href="/download/android-tv" className="phone-menu-platform-item">
                        <AiFillAndroid style={{ marginRight: 10 }} size={44} /> Android TV
                    </a>

                    <a href="https://apps.apple.com/id/app/vpn-matreshka-hotspot-shield/id1622803257" className="phone-menu-platform-item">
                        <AiFillApple style={{ marginRight: 10 }} size={44} /> iOS
                    </a>

                    <a href="/download/mac" className="phone-menu-platform-item">
                        <AiFillApple style={{ marginRight: 10 }} size={44} /> MacOS
                    </a>

                    {/* <a className="phone-menu-platform-item">
                        <AiFillApple style={{ marginRight: 10 }} size={44} /> Apple TV
                    </a> */}

                    <a href="/download/windows" className="phone-menu-platform-item">
                        <AiFillWindows style={{ marginRight: 10 }} size={44} /> Windows
                    </a>
                </div>
            </a>

            <a onMouseEnter={() => cursorChangeHandler("hover")}
                onMouseLeave={() => cursorChangeHandler("")} href="/about" className="phone-menu-route">
                 {t("navigation_route_3")}
            </a>

            <a onMouseEnter={() => cursorChangeHandler("hover")}
                onMouseLeave={() => cursorChangeHandler("")} href="/subscription" className="phone-menu-route">
                {t("navigation_route_4")}
            </a>

            <a onMouseEnter={() => cursorChangeHandler("hover")}
                onMouseLeave={() => cursorChangeHandler("")} href="https://matreshkavpn.com/support" className="phone-menu-route">
                {t("navigation_route_5")}
            </a>

            <a onMouseEnter={() => cursorChangeHandler("hover")}
                onMouseLeave={() => cursorChangeHandler("")} href="https://t.me/matreshkavpn_official" className="phone-menu-route">
                {t("navigation_route_6")}
            </a>
        </div>
    );
}

export default PhoneMenu;