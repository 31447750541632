import 'normalize.css';

import './styles/index.scss';

import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Main from './pages/main/Main';
import { useEffect } from 'react';
import BaseLayout from './components/layout/BaseLayout';
import Support from './pages/support/Support';
import NotFound from './pages/404/404';

import './utils/i18n'
import Oferta from './pages/oferta/Oferta';
import Confidential from './pages/confidential/Confidential';
import Update from './pages/update/Update';
import Login from './pages/login/Login';
import Profile from './pages/profile/Profile';
import Subscription from './pages/subscription/Subscription';
import About from './pages/about/About';
import SuccessPayment from './pages/payments/SuccessPayment';
import DownloadWindows from './pages/download/DownloadWindows';
import DownloadAndroid from './pages/download/DownloadAndroid';
import DownloadIos from './pages/download/DownloadIos';
import DownloadMacOs from './pages/download/DownloadMacOs';
import DownloadAndroidTv from './pages/download/DownloadAndroidTv';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Main />} />
        {/* <Route path='/contact' element={<Support />} /> */}
        <Route path='/about' element={<About />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/download/windows' element={<DownloadWindows />} />
        <Route path='/download/android' element={<DownloadAndroid />} />
        <Route path='/download/android-tv' element={<DownloadAndroidTv />} />
        <Route path='/download/mac' element={<DownloadMacOs />} />
        <Route path='/oferta' element={<Oferta />} />
        <Route path='/confidential' element={<Confidential />} />
        <Route path='/subscription' element={<Subscription />} />
        {/* <Route path='/update' element={<Update />} /> */}
        <Route path='/payment/success' element={<SuccessPayment />} />
        {/* <Route path='/login' element={<Login />} /> */}
        <Route path='/*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
