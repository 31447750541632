import { useContext } from "react";
import { MouseContext } from "../../utils/mouseContext";

const Button = (props) => {

    const { cursorChangeHandler } = useContext(MouseContext)

    return (
        <button onMouseEnter={() => cursorChangeHandler("hover")}
            onMouseLeave={() => cursorChangeHandler("")} {...props} className="base-button">
            {props.children}
        </button>
    );
}

export default Button;