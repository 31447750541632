import React, { createContext, useState } from "react";
export const ThemeContext = createContext({
    light: false,
    switchTheme: () => { }
})
const ThemeContextProvider = (props) => {
    const [theme, setTheme] = useState(false);
    const switchTheme = (theme) => {
        setTheme(theme);
    };
    return (
        <ThemeContext.Provider
            value={{
                theme,
                switchTheme
            }}
        >
            {props.children}
        </ThemeContext.Provider>
    );
};
export default ThemeContextProvider;