import React, { useRef, useState, useEffect, useMemo } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, useFBX, useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useSpring, animated } from "@react-spring/three";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { AMFLoader } from 'three/examples/jsm/loaders/AMFLoader'
import { useLoader } from '@react-three/fiber'
import { MathUtils } from "three";
THREE.DefaultLoadingManager.addHandler(/\.dds$/i, new DDSLoader());
// ref.current.position.x = Math.cos(window.scrollY / 600) * 1.9;


function SphereGray(props) {
  const obj = useLoader(OBJLoader, "test-gray.obj", (obj) => {
    // obj.setMaterials(mat)
  });

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const ref = useRef();

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();

    ref.current.position.y = Math.cos(time) * 0.3;
    ref.current.rotation.y += 0.003;
    ref.current.rotation.x = Math.cos(time) * 0.2;
    ref.current.position.x = Math.cos(window.scrollY / 600) * 1.9;
  });

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      // ref.current.position.x = MathUtils.randInt(1, 2)
      // ref.current.position.y = -window.scrollY / 250;
    })
  }, [])

  useThree((props) => {
    props.camera.position.y = 0;
    props.camera.position.x = 0;
  })

  return (
    <>
      <primitive scale={10} ref={ref} object={obj} />
    </>
  );
}

export default SphereGray;