import Footer from "../footer/Footer";
import Header from "../header/Header";
import PhoneMenu from "../menu/PhoneMenu";
import Preloader from "../preloader/PreloaderWrapper";

const BaseLayout = ({
    children,
    light = false,
    style,
    containerStyle
}) => {

    return (
        <div style={{
            height: "100%",
            ...style
        }}>
            <Preloader>
                <PhoneMenu light={light} />
                <Header light={light} />
                <div style={{ ...containerStyle }} className={`content-container ${light && "light"}`}>
                    {children}
                </div>
                <Footer light={light} />
            </Preloader>
        </div>
    );
}

export default BaseLayout;