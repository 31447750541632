import React, { useEffect, useRef } from 'react';

const SuccessPayment = () => {

    const ref = useRef();

    useEffect(() => {
        window.location = "matreshka://payment?paymentStatus=true"
    }, [])

    return (
        <>
            <a ref={ref} href="matreshka://payment?paymentStatus=true">Wait...</a>
        </>
    );
}

export default SuccessPayment;