import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";
import BaseLayout from '../../components/layout/BaseLayout';
import { Helmet } from 'react-helmet'
import { MouseContext } from '../../utils/mouseContext';

const Update = () => {
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [error, setError] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [load, setLoad] = useState(false);
    const [anim, setAnim] = useState(true);
    const [updates, setUpdates] = useState([])

    const { cursorChangeHandler } = useContext(MouseContext);

    const getUpdates = async () => {
        const data = await axios.get("https://new.matreshkavpn.com/api/v1/update/");

        setUpdates(data.data.sort((a, b) => a.position - b.position))
    }

    useEffect(() => {
        getUpdates()
    }, [])

    const [selectedVersion, setSelectedVersion] = useState(0)

    const ref = useRef(null)

    const submitForm = async () => {
        if (title.length == 0) {
            setLoad(false)
            return setError("Введите тему предложения")
        }

        if (content.length == 0) {
            setLoad(false)
            return setError("Введите описание предложения")

        }

        if (content.length < 64 && content.length > 2048) {
            setLoad(false)
            return setError("Минимальная длина описания 64, максимальная 2048")
        }

        if (title.length < 3 && title.length > 64) {
            setLoad(false)
            return setError("Минимальная длина темы 3, максимальная 64")
        }

        try {
            const result = await axios.post("https://tuatara.space/api/v1/feature-ticket/", {
                title,
                content
            })

            if (result.status == 200) {
                setShowModal(true)
            } else
                setError("Ошибка при отправке предложения. Проверьте валидность данных или попробуйте позже!")

            setLoad(false);
        } catch (e) {
            setError("Ошибка при отправке предложения. Проверьте валидность данных или попробуйте позже!")
            setLoad(false);
        }


        setLoad(false);
    }

    const closeModal = () => setShowModal(false)

    useMemo(() => {
        console.log(selectedVersion)
    }, [selectedVersion])

    const particlesInit = useCallback(async (engine) => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        await console.log(container);
    }, []);

    return (
        <>
            <Helmet>
                <title>Обовления - MatreshkaVPN</title>
            </Helmet>
            <BaseLayout light={true}>
                <div className="update-container">
                    {/* <Particles
                        id="tsparticles"
                        init={particlesInit}
                        loaded={particlesLoaded}
                        options={{
                            "interactivity": {
                                "detect_on": "canvas",
                                "events": {
                                    "onhover": {
                                        "enable": true,
                                        "mode": "repulse"
                                    },
                                    "onclick": {
                                        "enable": false,
                                        "mode": "remove"
                                    },
                                    "resize": true
                                },
                                "modes": {
                                    "grab": {
                                        "distance": 400,
                                        "line_linked": {
                                            "opacity": 1
                                        }
                                    },
                                    "bubble": {
                                        "distance": 400,
                                        "size": 40,
                                        "duration": 2,
                                        "opacity": 8,
                                        "speed": 3
                                    },
                                    "repulse": {
                                        "distance": 200,
                                        "duration": 0.4
                                    },
                                    "push": {
                                        "particles_nb": 4
                                    },
                                    "remove": {
                                        "particles_nb": 2
                                    }
                                }
                            },
                            "particles": {
                                "number": {
                                    "value": 80,
                                    "density": {
                                        "enable": true,
                                        "value_area": 800
                                    }
                                },
                                "color": {
                                    "value": "#2A2927"
                                },
                                "shape": {
                                    "type": "circle",
                                    "stroke": {
                                        "width": 0,
                                        "color": "#000000"
                                    },
                                    "polygon": {
                                        "nb_sides": 5
                                    },
                                },
                                "opacity": {
                                    "value": 0.5,
                                    "random": false,
                                    "anim": {
                                        "enable": false,
                                        "speed": 1,
                                        "opacity_min": 0.1,
                                        "sync": false
                                    }
                                },
                                "size": {
                                    "value": 3,
                                    "random": true,
                                    "anim": {
                                        "enable": false,
                                        "speed": 20,
                                        "size_min": 0.1,
                                        "sync": false
                                    }
                                },
                                "line_linked": {
                                    "enable": true,
                                    "distance": 150,
                                    "color": "#2A2927",
                                    "opacity": 0.4,
                                    "width": 1
                                },
                                "move": {
                                    "enable": true,
                                    "speed": 3,
                                    "direction": "none",
                                    "random": false,
                                    "straight": false,
                                    "out_mode": "out",
                                    "bounce": false,
                                    "attract": {
                                        "enable": false,
                                        "rotateX": 600,
                                        "rotateY": 1200
                                    }
                                }
                            }
                        }} /> */}
                    {/* <ReactModal
                className={"modal-animate"}
                isOpen={showModal}
                onRequestClose={closeModal}
                style={{
                    overlay: {
                        zIndex: 4,
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 0
                    },
                    content: {
                        zIndex: 4,
                        position: 'initial',
                        width: '400px',
                        height: 'max-content',
                        padding: 10,
                        backgroundColor: "#231836",
                        border: 0
                    }
                }}
            >
                <p className="update-modal-success-result">
                    Предложение оптравлено
                </p>

                <Button
                    style={{
                        width: "100%"
                    }}
                    onClick={closeModal}
                    color={'primary'}>
                    Закрыть
                </Button>
            </ReactModal> */}
                    <div className="update-page-container">

                        {
                            updates.map((item, idx) => (
                                <div className={`update-page-horizontal-content-container ${idx != selectedVersion && "hide"}`}>

                                    <p className="update-page-content-header">
                                        {item.title}
                                    </p>

                                    <p className="update-page-content-body">
                                        {item.description}
                                    </p>
                                </div>
                            ))
                        }

                        <div className="update-page-horizontal-navigation-container">
                            <div className="update-page-navigation-horizontal-menu-container">
                                <div ref={ref} className="update-page-navigation-horizontal-menu-list" style={{
                                    width: 160 * updates.length,
                                    transform: `translateX(-${(selectedVersion * 150) > ((updates.length - 2) * 150) ? (updates.length - 2) * 150 : selectedVersion * 150}px)`
                                }}>
                                    {
                                        updates.map((item, idx) => (
                                            <div style={{
                                                marginLeft: idx == 0 ? 140 : 0,
                                                justifyContent: 'center'
                                            }} className="version-horizontal-item-container">

                                                <span className={`version-horizontal-item-indicator ${idx == selectedVersion && "active"}`} />

                                                <p onMouseEnter={() => cursorChangeHandler("hover")} onMouseLeave={() => cursorChangeHandler("")} className={`version-horizontal-item-text ${idx == selectedVersion && "active"}`} onClick={() => {
                                                    setSelectedVersion(idx);
                                                }}>
                                                    Matreshka {item.version}
                                                </p>

                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="update-page-navigation-container">
                            <div className="update-page-navigation-menu-container">
                                <div ref={ref} onWheel={(e) => {
                                    if (e.nativeEvent.deltaY > 40 && updates.length - 1 > selectedVersion) {
                                        setSelectedVersion(selectedVersion + 1)
                                    }

                                    if (e.nativeEvent.deltaY < -40 && selectedVersion > 0) {
                                        setSelectedVersion(selectedVersion - 1)
                                    }
                                }} className="update-page-navigation-menu-list" style={{
                                    height: 50 * updates.length,
                                    transform: `translateY(-${(selectedVersion * 50) > ((updates.length - 4) * 50) ? (updates.length - 4) * 50 : selectedVersion * 50}px)`
                                }}>
                                    {
                                        updates.map((item, idx) => (
                                            <div style={{
                                                marginTop: idx == 0 ? 50 : 0
                                            }} className="version-item-container">
                                                <p onMouseEnter={() => cursorChangeHandler("hover")} onMouseLeave={() => cursorChangeHandler("")} className={`version-item-text ${idx == selectedVersion && "active"}`} onClick={() => {
                                                    setSelectedVersion(idx);
                                                }}>
                                                    Matreshka {item.version}
                                                </p>

                                                <span className={`version-item-indicator ${idx == selectedVersion && "active"}`} />
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        </div>

                        {
                            updates.map((item, idx) => (
                                <div className={`update-page-content-container ${idx != selectedVersion && "hide"}`}>

                                    <p className="update-page-content-header">
                                        {item.title}
                                    </p>

                                    <p className="update-page-content-body">
                                        {item.description}
                                    </p>
                                </div>
                            ))
                        }


                        {/* <div ref={ref} className="update-page-navigation-container">
                    {
                        versions.map((item, idx) => (
                            <div className="version-item-container">
                                <p className={`version-item-text ${idx == selectedVersion && "active"}`} onClick={() => {
                                    setSelectedVersion(idx);
                                    
                                    ref.current.scrollTo({
                                        top: idx * 50,
                                        behavior: "smooth"
                                    })
                                }}>
                                    {item}
                                </p>

                                <div className="version-item-indicator" />
                            </div>
                        ))
                    }
                </div> */}
                    </div>

                    {/* <div className="background-slipes">
                <img height={1000} width={1000} style={{
                    position: 'absolute',
                    top: -250,
                    right: -250,
                    zIndex: -1
                }} src={require("../assets/images/decorative-blur-2.png")} />

                <img height={1000} width={1000} style={{
                    position: 'absolute',
                    top: 400,
                    left: -350,
                    zIndex: -1
                }} src={require("../assets/images/decorative-blur.png")} />

                <img height={1000} width={1000} style={{
                    position: 'absolute',
                    top: 1500,
                    right: -250,
                    zIndex: -1
                }} src={require("../assets/images/decorative-blur-2.png")} />

                <img height={1000} width={1000} style={{
                    position: 'absolute',
                    top: 1200,
                    left: -350,
                    zIndex: -1
                }} src={require("../assets/images/decorative-blur-3.png")} />

                <img height={1000} width={1000} style={{
                    position: 'absolute',
                    top: 2200,
                    right: -350,
                    zIndex: -1
                }} src={require("../assets/images/decorative-blur-2.png")} />

                <img height={1000} width={1000} style={{
                    position: 'absolute',
                    top: 2600,
                    left: -450,
                    zIndex: -1
                }} src={require("../assets/images/decorative-blur-3.png")} /> */}
                    {/* </ */}
                    {/* </div> */}
                </div>

            </BaseLayout>
        </>
    );
}

export default Update;