import React from "react";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import BaseLayout from "../../components/layout/BaseLayout";
import parse from "html-react-parser";
import {
  AiFillApple,
  AiFillAppstore,
  AiFillWindows,
  AiOutlineCloudDownload,
} from "react-icons/ai";
import {useNavigate} from "react-router-dom";

const DownloadMacOs = () => {
  const {t} = useTranslation();

  const navigate = useNavigate();

  return (
    <>
      <Helmet></Helmet>
      <BaseLayout>
        <div className="download-page-content content-margin-wrapper">
          <div className="download-page-left">
            <p className="download-page-header">
              {parse(t("download_vpn_mac_header"))}
            </p>

            <p className="download-page-subheader">
              {parse(t("download_vpn_mac_subheader"))}
            </p>

            <p className="download-page-desc">
              {parse(t("download_vpn_mac_desc"))}
            </p>

            <div className="download-page-actions-container">
              <div
                onClick={() => {
                  window.location.replace(
                    "https://apps.apple.com/id/app/vpn-matreshka-hotspot-shield/id1622803257"
                  );
                }}
                className="download-page-action-button-container"
              >
                <p className="download-page-actions-button">
                  <AiFillApple
                    style={{
                      marginRight: 15,
                      width: 24,
                      height: 24,
                      position: "relative",
                      bottom: 2,
                    }}
                  />
                  {/* <img src={require("../../assets/images/google-play.svg").default} style={{
                                        height: 24,
                                        width: 24,
                                        marginRight: 15
                                    }} /> */}
                  {parse(t("download_vpn_mac_button_1"))}
                </p>
              </div>

              <div className="download-page-action-button-container">
                <p
                  onClick={() => {
                    window.open("/files/MatreshkaVPNMacOs.zip");
                  }}
                  className="download-page-actions-button"
                >
                  <img
                    src={
                      require("../../assets/images/website-download.svg")
                        .default
                    }
                    style={{
                      height: 24,
                      width: 24,
                      marginRight: 15,
                    }}
                  />
                  {parse(t("download_vpn_mac_button_2"))}
                </p>
              </div>
            </div>
          </div>

          <div className="download-page-right">
            <img
              src={"https://matreshkavpn.com" + t("download_vpn_mac_image")}
              className="download-page-right-image"
            />
          </div>
        </div>
      </BaseLayout>
    </>
  );
};

export default DownloadMacOs;
