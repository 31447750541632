import React, { useRef, useState, useEffect, useMemo } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, useFBX, useGLTF, useTexture } from "@react-three/drei";
import { useSpring, animated } from "@react-spring/three";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { AMFLoader } from 'three/examples/jsm/loaders/AMFLoader'
import { useLoader } from '@react-three/fiber'
import { MathUtils } from "three";
import * as THREE from 'three'
import gsap, { Sine } from "gsap";
// ref.current.position.x = Math.cos(window.scrollY / 600) * 1.9;


function Globe(props) {
  const ref = useRef();
  const obj = useLoader(OBJLoader, "test.obj", (obj) => {

  });
  // useFrame(({ clock }) => {
  //   const time = clock.getElapsedTime();

  //   ref.current.position.y = Math.cos(time) * 0.3;
  //   ref.current.rotation.y += 0.003;
  //   ref.current.rotation.x = Math.cos(time) * 0.2;
  //   ref.current.position.x = Math.cos(window.scrollY / 600) * 1.9;
  // });

  // useEffect(() => {
  //   window.addEventListener("scroll", (e) => {
  //     // ref.current.position.x = MathUtils.randInt(1, 2)
  //     // ref.current.position.y = -window.scrollY / 250;
  //   })
  // }, [])

  const three = useThree()

  useEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const scene = three.scene;
    scene.background = new THREE.Color(0xffffff);
    scene.fog = new THREE.FogExp2(0xffffff, 0.05);

    const objMaterial = new THREE.MeshBasicMaterial({
      color: 0x000,
      transparent: true,
      opacity: 0.3,
      wireframe: true,
      wireframeLinecap: 1000
    });

    ref.current.traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.material = objMaterial;
      }
    });

    const anim = { camera: 4, opacity: -1, elem: 0, next: false, rotation: -3 };
    // TweenMax.to(ref.current.position, 1000, { x: 11, y: 222, ease: Sine.easeIn }).play();
    // gsap.to(ref.current.scale, { duration: 3, scaleX:2, yoyo: true, repeat: -1 })
    gsap.fromTo(ref.current.rotation, {y: -.2, x: -.1, z: -.1 }, { duration: 4, y: 6, x: .5, z: .3, repeat: -1, repeatDelay: .01, yoyo: true, ease: Sine.easeInOut })
    gsap.to(ref.current.scale, { duration: 4, y: 33, x: 33, z: 33, yoyo: true, repeat: -1, repeatDelay: .01 })

    // three.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    three.camera.position.z = 25;
    three.camera.fov = 35
    three.camera.far = 1000
    three.camera.aspect = width / height
    three.camera.near = .1

    const renderer = new THREE.WebGLRenderer({
      antialias: true
    });
    renderer.setSize(width, height);
    document.body.appendChild(renderer.domElement);

    const circlesGroup = new THREE.Group();
    scene.add(circlesGroup);

    const circleRadius = 8;
    const numCircles = 15;
    const numSpheres = 10;

    // Создание прозрачного глобуса с широтами и долготами
    const globeRadius = 7;
    const globeSegments = 10;
    const globeMaterial = new THREE.MeshBasicMaterial({
      color: 0x000000,
      transparent: true,
      opacity: 0.3,
      wireframe: true
    });
    const globeGeometry = new THREE.SphereGeometry(globeRadius, globeSegments, globeSegments);
    const globe = new THREE.Mesh(globeGeometry, globeMaterial);
    scene.add(globe);

    for (let i = 0; i < numCircles; i++) {
      const circleRandomRadius = circleRadius + Math.random() * 2;
      const circleGeometry = new THREE.CircleGeometry(circleRandomRadius, 10);
      const circleMaterial = new THREE.MeshBasicMaterial({
        color: 0x000000,
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0
      });
      const circle = new THREE.Mesh(circleGeometry, circleMaterial);
      circle.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, Math.random() * Math.PI);
      circlesGroup.add(circle);

      for (let j = 0; j < numSpheres; j++) {
        const sphereGeometry = new THREE.SphereGeometry(0.1, 32, 32);
        const sphereMaterial = new THREE.MeshBasicMaterial({
          color: 0x000000
        });
        const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

        const angle = (j / 5) * Math.PI * 2;
        sphere.position.set(circleRandomRadius * Math.cos(angle), 0, circleRandomRadius * Math.sin(angle));
        circle.add(sphere);
      }
    }

    let lastTime = performance.now();

    function updateSpheres(delta) {
      circlesGroup.children.forEach((circle) => {
        circle.children.forEach((sphere, index) => {
          const angle = 2 * Math.PI * index / numSpheres + delta;
          sphere.position.set(circle.geometry.parameters.radius * Math.cos(angle), circle.geometry.parameters.radius * Math.sin(angle), 0);
        });
      });
    }

    function animate() {
      requestAnimationFrame(animate);

      const currentTime = performance.now();
      const delta = (currentTime - lastTime) / 1000;
      lastTime = currentTime;

      updateSpheres(delta);

      circlesGroup.children.forEach((circle) => {
        circle.rotateOnAxis(new THREE.Vector3(0, 0, 0.8), delta);
      });

      globe.rotation.y += 0.003;

      renderer.render(scene, three.camera);
    }

    animate();
  }, [])


  return (
    <>
      <primitive material={null} scale={30} ref={ref} object={obj} />
    </>
  );
}

export default Globe;