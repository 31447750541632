import React from 'react';

const BackThreeLines = ({ style }) => {

    return (
        <div className="back-three-lines-container" style={style}>
            <div className="back-three-lines-item-container first">
                <div className="back-three-lines-element" />
                <div className="back-three-lines-element second" />
            </div>

            <div className="back-three-lines-item-container second">
                <div className="back-three-lines-element" />
                <div className="back-three-lines-element second" />
            </div>

            <div className="back-three-lines-item-container third">
                <div className="back-three-lines-element" />
                <div className="back-three-lines-element second" />
            </div>
        </div>
    );
}

export default BackThreeLines;