import { Suspense, useContext, useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";
import parse from 'html-react-parser'

import * as THREE from 'three'
import { TweenMax, Elastic, Power2, Power3 } from "gsap/gsap-core";
import Sphere from "../../components/sphere/Sphere";
import { Canvas, useThree } from "@react-three/fiber";
import Footer from "../../components/footer/Footer";
import BaseLayout from "../../components/layout/BaseLayout";
import Button from "../../components/button/Button";
import Scramble from "react-scramble";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { MouseContext } from "../../utils/mouseContext";
import NoVpn from '../../assets/images/tt.svg'
import WithVpn from '../../assets/images/with-vpn.svg'
import Preloader from "../../components/preloader/PreloaderWrapper";
import BackThreeLines from "../../components/back-three-lines/BackThreeLines";

const Main = () => {
    const { cursorChangeHandler } = useContext(MouseContext)

    const [load, setLoad] = useState(true)

    const { t, i18n } = useTranslation()

    const scrolledWordRef = useRef(null)
    const scrolledTopWordRef = useRef(null)
    const scrolledWordBlockRef = useRef(null)

    const canvasRef = useRef(null)

    const flyingSymbol1 = useRef(null)
    const flyingSymbol2 = useRef(null)
    const flyingSymbol3 = useRef(null)
    const flyingSymbol4 = useRef(null)
    const flyingSymbol5 = useRef(null)
    const flyingSymbol6 = useRef(null)
    const flyingSymbol7 = useRef(null)
    const flyingSymbolsContainer = useRef(null)

    const [showSecondBlock, setShowSecondBlock] = useState(false);
    const [selectedIllustration, setSelectedIllustration] = useState(1);

    useEffect(() => {

        window.addEventListener("scroll", (e) => {
            if ((scrolledWordBlockRef.current.offsetTop - window.innerHeight) <= window.scrollY)
                if (!showSecondBlock)
                    setShowSecondBlock(true)

            if (window.scrollY > scrolledWordBlockRef.current.offsetTop && (window.scrollY - scrolledWordBlockRef.current.offsetTop + 450) < scrolledWordBlockRef.current.offsetHeight) {
                scrolledWordRef.current.style.transform = `translate3d(0px, ${((window.scrollY - scrolledWordBlockRef.current.offsetTop))}px, 0px)`
                scrolledTopWordRef.current.style.transform = `translate3d(0px, ${((window.scrollY - scrolledWordBlockRef.current.offsetTop))}px, 0px)`
            } else if ((window.scrollY - scrolledWordBlockRef.current.offsetTop - 30) < 0) {
                scrolledWordRef.current.style.transform = `translate3d(0px, 0px, 0px)`
                scrolledTopWordRef.current.style.transform = `translate3d(0px, 0px, 0px)`
            }

            if (window.scrollY < flyingSymbolsContainer.current.offsetTop - 500) {
                flyingSymbol1.current.style.transform = `translate3d(-79px, -62px, 0px) rotate(-66deg)`
                flyingSymbol2.current.style.transform = `translate3d(-52px, -54px, 0px) rotate(11deg)`
                flyingSymbol3.current.style.transform = `translate3d(-33px, -59px, 0px) rotate(-42deg)`
                flyingSymbol4.current.style.transform = `translate3d(-12px, -82px, 0px) rotate(-11deg)`
                flyingSymbol5.current.style.transform = `translate3d(11px, -62px, 0px) rotate(7deg)`
                flyingSymbol6.current.style.transform = `translate3d(37px, -62px, 0px) rotate(11deg)`
                flyingSymbol7.current.style.transform = `translate3d(69px, -62px, 0px) rotate(33deg)`
            } else if (window.scrollY < flyingSymbolsContainer.current.offsetTop - 480) {
                flyingSymbol1.current.style.transform = `translate3d(-59px, -51px, 0px) rotate(-51deg)`
                flyingSymbol2.current.style.transform = `translate3d(-41px, -44px, 0px) rotate(7deg)`
                flyingSymbol3.current.style.transform = `translate3d(-17px, -42px, 0px) rotate(-32deg)`
                flyingSymbol4.current.style.transform = `translate3d(-6px, -55px, 0px) rotate(-4deg)`
                flyingSymbol5.current.style.transform = `translate3d(7px, -34px, 0px) rotate(4deg)`
                flyingSymbol6.current.style.transform = `translate3d(22px, -52px, 0px) rotate(7deg)`
                flyingSymbol7.current.style.transform = `translate3d(51px, -53px, 0px) rotate(16deg)`
            } else if (window.scrollY < flyingSymbolsContainer.current.offsetTop - 460) {
                flyingSymbol1.current.style.transform = `translate3d(-43px, -31px, 0px) rotate(-27deg)`
                flyingSymbol2.current.style.transform = `translate3d(-28px, -39px, 0px) rotate(4deg)`
                flyingSymbol3.current.style.transform = `translate3d(-11px, -35px, 0px) rotate(-14deg)`
                flyingSymbol4.current.style.transform = `translate3d(-2px, -33px, 0px) rotate(-1deg)`
                flyingSymbol5.current.style.transform = `translate3d(3px, -41px, 0px) rotate(1deg)`
                flyingSymbol6.current.style.transform = `translate3d(8px, -38px, 0px) rotate(3deg)`
                flyingSymbol7.current.style.transform = `translate3d(35px, -39px, 0px) rotate(11deg)`
            } else if (window.scrollY < flyingSymbolsContainer.current.offsetTop - 440) {
                flyingSymbol1.current.style.transform = `translate3d(-12px, -14px, 0px) rotate(-12deg)`
                flyingSymbol2.current.style.transform = `translate3d(-8px, -16px, 0px) rotate(0deg)`
                flyingSymbol3.current.style.transform = `translate3d(-2px, -11px, 0px) rotate(-4deg)`
                flyingSymbol4.current.style.transform = `translate3d(0px, -8px, 0px) rotate(0deg)`
                flyingSymbol5.current.style.transform = `translate3d(0px, -16px, 0px) rotate(0deg)`
                flyingSymbol6.current.style.transform = `translate3d(2px, -14px, 0px) rotate(0deg)`
                flyingSymbol7.current.style.transform = `translate3d(14px, -9px, 0px) rotate(4deg)`
            } else if (window.scrollY < flyingSymbolsContainer.current.offsetTop) {
                flyingSymbol1.current.style.transform = `translate3d(0, -10px, 0px) rotate(0deg)`
                flyingSymbol2.current.style.transform = `translate3d(0, -10px, 0px) rotate(0deg)`
                flyingSymbol3.current.style.transform = `translate3d(0, -10px, 0px) rotate(0deg)`
                flyingSymbol4.current.style.transform = `translate3d(0, -10px, 0px) rotate(0deg)`
                flyingSymbol5.current.style.transform = `translate3d(0, -10px, 0px) rotate(0deg)`
                flyingSymbol6.current.style.transform = `translate3d(0, -10px, 0px) rotate(0deg)`
                flyingSymbol7.current.style.transform = `translate3d(0, -10px, 0px) rotate(0deg)`
            }
        })

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (!entry.target.classList.contains("show"))
                        entry.target.classList.add("show");
                }
            });
        });


        document.querySelectorAll(".main-page-second-block-container").forEach((el) => {
            observer.observe(el)
        })

        observer.observe(document.querySelector(".main-page-faq-container"));
        observer.observe(document.querySelector(".main-page-faq-container"));

        document.querySelectorAll(".main-page-faq-question-container").forEach((el) => {
            observer.observe(el)
        })

        document.querySelectorAll(".main-page-tarifs-inner-block").forEach((el) => {
            observer.observe(el)
        })

        observer.observe(document.querySelector(".main-page-vpn-work-illutration-controls-container"))
        observer.observe(document.querySelector(".main-page-vpn-work-illutration-content-container"))

        setTimeout(() => setLoad(false), 2000)

        // window.loadParticles()

    }, [])

    return (
        <>
            <Helmet>
                <title>{parse(t("home_page_title"))}</title>
            </Helmet>
            <div className="main-page-wrapper">
                <BaseLayout>
                    <div className="main-page-container">
                        <div className="main-page-hero-container content-margin-wrapper">
                            <p className="main-page-hero-text">
                                {t("app_name")}
                            </p>

                            <p className="main-page-hero-subtext">
                                {parse(t("home_page_header_subtext"))}
                            </p>
                        </div>

                        <div ref={scrolledWordBlockRef} className={`main-page-second-container content-margin-wrapper ${showSecondBlock && "show"}`}>
                            <div className="main-page-second-left-container">
                                <p ref={scrolledTopWordRef} className="main-page-second-left-top-text">
                                    #MatreshkaVPN
                                </p>
                                <p className="main-page-second-left-text" ref={scrolledWordRef}>{parse(t("home_page_float_text"))}</p>
                            </div>

                            <div className="main-page-second-right-container">
                                <div className="main-page-second-block-container">
                                    <p className="main-page-second-block-header">
                                        {parse(t("home_page_second_block_right_header_1"))}
                                    </p>

                                    <p className="main-page-second-block-desc">
                                        {parse(t("home_page_second_block_right_description_1"))}
                                    </p>
                                </div>

                                <div className="main-page-second-block-container">
                                    <p className="main-page-second-block-header">
                                        {parse(t("home_page_second_block_right_header_2"))}
                                    </p>

                                    <p className="main-page-second-block-desc">
                                        {parse(t("home_page_second_block_right_description_2"))}
                                    </p>
                                </div>

                                <div className="main-page-second-block-container">
                                    <p className="main-page-second-block-header">
                                        {parse(t("home_page_second_block_right_header_3"))}
                                    </p>

                                    <p className="main-page-second-block-desc">
                                        {parse(t("home_page_second_block_right_description_3"))}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="main-page-faq-container content-margin-wrapper">
                            <p className="main-page-faq-header">
                                {parse(t("home_page_faq_header"))}
                            </p>

                            <div className="main-page-faq-question-row">
                                <div className="main-page-faq-question-container">
                                    <p className="main-page-faq-question-header">
                                        {parse(t("home_page_faq_question_header_1"))}
                                    </p>

                                    <p className="main-page-faq-question-desc">
                                        {parse(t("home_page_faq_question_description_1"))}
                                    </p>
                                </div>

                                <div className="main-page-faq-question-container">
                                    <p className="main-page-faq-question-header">
                                        {parse(t("home_page_faq_question_header_2"))}
                                    </p>

                                    <p className="main-page-faq-question-desc">
                                        {parse(t("home_page_faq_question_description_2"))}
                                    </p>
                                </div>
                            </div>

                            <div className="main-page-vpn-work-illustration-container">
                                <div className="main-page-vpn-work-illutration-controls-container">
                                    {/* <div onClick={() => setSelectedIllustration(1)} onMouseEnter={() => cursorChangeHandler("hover")} onMouseLeave={() => cursorChangeHandler("")} className={`main-page-vpn-work-illustration-controls-item-container ${selectedIllustration == 1 && "primary"}`}>
                                        <span className="main-page-vpn-work-illustration-controls-span" />

                                        <p className="main-page-vpn-work-illustration-controls-text">
                                            Работа сети без Matreshka VPN
                                        </p>
                                    </div>

                                    <div onClick={() => setSelectedIllustration(0)} onMouseEnter={() => cursorChangeHandler("hover")} onMouseLeave={() => cursorChangeHandler("")} className={`main-page-vpn-work-illustration-controls-item-container ${selectedIllustration == 0 && "primary"}`}>
                                        <span className="main-page-vpn-work-illustration-controls-span" />

                                        <p className="main-page-vpn-work-illustration-controls-text">
                                            Работа сети с Matreshka VPN
                                        </p>
                                    </div> */}
                                </div>

                                <div className="main-page-vpn-work-switch-wrapper">
                                    <div className="main-page-vpn-work-switch-container">
                                        <div className={`main-page-vpn-work-switch-background ${selectedIllustration == 1 ? 'switched' : ''}`} />

                                        <div className="main-page-vpn-work-swtich-controls-container">
                                            <p onClick={() => setSelectedIllustration(0)} className={`main-page-vpn-work-switch-control ${selectedIllustration == 0 ? 'switched' : ''}`}>
                                                {parse(t("home_page_illustration_switch_without"))}
                                            </p>

                                            <p onClick={() => setSelectedIllustration(1)} className={`main-page-vpn-work-switch-control ${selectedIllustration == 1 ? 'switched' : ''}`}>
                                                {parse(t("home_page_illustration_switch_with"))}
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className={`main-page-vpn-work-illutration-content-container ${selectedIllustration == 0 ? "second" : selectedIllustration == 1 ? "first" : ""}`}>
                                    <img src={WithVpn} style={{ width: "100%" }} className="first" />
                                    <img src={NoVpn} style={{ width: "100%" }} className="second" />
                                </div>
                            </div>
                        </div>

                        {/* <div className="main-page-learn-container">
                            <p className="main-page-learn-top-header">
                                FIND BUGS EARLY
                            </p>

                            <p className="main-page-learn-header">
                                FIND BUGS EARLY
                            </p>

                            <p className="main-page-learn-desc">
                                Ockom’s AppSec services are designed to integrate with each other and complement a full spectrum cybersecurity solution. Which is a lot of mumbo jumbo to say that we can help you make sure you didn’t build in a vulnerability into your code pre-deployment.
                            </p>

                            <div className="main-page-learn-button-container">
                                <Button>
                                    LEARN MORE
                                </Button>
                            </div>
                        </div> */}

                        <div className="main-page-tarifs-container">
                            <div ref={flyingSymbolsContainer} className="main-page-tarifs-header-container">
                                <p ref={flyingSymbol1} className="main-page-tarifs-header-symbol">F</p>
                                <p ref={flyingSymbol2} className="main-page-tarifs-header-symbol">R</p>
                                <p ref={flyingSymbol3} className="main-page-tarifs-header-symbol">E</p>
                                <p ref={flyingSymbol4} className="main-page-tarifs-header-symbol">E</p>
                                <p ref={flyingSymbol5} className="main-page-tarifs-header-symbol">D</p>
                                <p ref={flyingSymbol6} className="main-page-tarifs-header-symbol">O</p>
                                <p ref={flyingSymbol7} className="main-page-tarifs-header-symbol">M</p>
                            </div>



                            <div className="main-page-tarifs-list-container">
                                <div className="main-page-tarifs-accent-bg" />

                                <BackThreeLines />

                                <p className="main-page-tarifs-list-header">
                                    {parse(t("home_page_vpn_facts_header"))}
                                </p>

                                <div className="main-page-tarifs-list-inner-container">
                                    <p className="main-page-tarifs-list-inner-desc">
                                        {parse(t("home_page_vpn_facts_inner_desc"))}
                                    </p>

                                    <div className="main-page-tarifs-inner-block first">
                                        <p className="main-page-tarifs-inner-header">
                                            {parse(t("home_page_vpn_facts_inner_header_1"))}
                                        </p>

                                        <p className="main-page-tarifs-inner-desc">
                                            {parse(t("home_page_vpn_facts_inner_desc_1"))}
                                        </p>
                                    </div>

                                    <div className="main-page-tarifs-inner-block second">
                                        <p className="main-page-tarifs-inner-header">
                                            {parse(t("home_page_vpn_facts_inner_header_2"))}
                                        </p>

                                        <p className="main-page-tarifs-inner-desc">
                                            {parse(t("home_page_vpn_facts_inner_desc_2"))}
                                        </p>
                                    </div>

                                    <div className="main-page-tarifs-inner-block third">
                                        <p className="main-page-tarifs-inner-header">
                                            {parse(t("home_page_vpn_facts_inner_header_3"))}
                                        </p>

                                        <p className="main-page-tarifs-inner-desc">
                                            {parse(t("home_page_vpn_facts_inner_desc_3"))}
                                        </p>
                                    </div>

                                    <div className="main-page-tarifs-inner-block four">
                                        <p className="main-page-tarifs-inner-header">
                                            {parse(t("home_page_vpn_facts_inner_header_4"))}
                                        </p>

                                        <p className="main-page-tarifs-inner-desc">
                                            {parse(t("home_page_vpn_facts_inner_desc_4"))}
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="main-page-tarif-container">
                                    <p className="main-page-tarif-name">
                                        Тариф 1
                                    </p>

                                    <p className="main-page-tarif-desc">
                                        ТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТариф
                                    </p>

                                    <Button>
                                        Купить за 299р.
                                    </Button>
                                </div>

                                <div className="main-page-tarif-container">
                                    <p className="main-page-tarif-name">
                                        Тариф 1
                                    </p>

                                    <p className="main-page-tarif-desc">
                                        ТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТариф
                                    </p>

                                    <Button>
                                        Купить за 299р.
                                    </Button>
                                </div>

                                <div className="main-page-tarif-container">
                                    <p className="main-page-tarif-name">
                                        Тариф 1
                                    </p>

                                    <p className="main-page-tarif-desc">
                                        ТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТарифТариф
                                    </p>

                                    <Button>
                                        Купить за 299р.
                                    </Button>
                            </div> */ }
                            </div>
                        </div>
                    </div>
                </BaseLayout>
            </div >

            <Canvas ref={canvasRef} style={{
                position: 'fixed',
                height: window.innerHeight,
                top: 0,
                zIndex: 1
            }}>
                <spotLight position={[20, 20, 20]} angle={0.15} penumbra={1} />
                <Suspense>
                    <Sphere />
                </Suspense>
            </Canvas>

            <script>

            </script>
        </>
    );
}

export default Main;