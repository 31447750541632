import React, { createContext, useState } from "react";
export const MenuContext = createContext({
    show: false,
    toggle: () => { }
})
const MenuContextProvider = (props) => {
    const [show, setShow] = useState(false);
    const toggle = () => {
        setShow(!show);
    };
    return (
        <MenuContext.Provider
            value={{
                show,
                toggle,
            }}
        >
            {props.children}
        </MenuContext.Provider>
    );
};
export default MenuContextProvider;