import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
export const AuthContext = createContext({
    user: null,
    logout: () => { },
    login: () => { },
    setUserData: () => { },
    inited: false
})
const AuthContextProvider = (props) => {
    const [user, setUser] = useState(null);
    const [inited, setInited] = useState(false);
    
    const logout = () => {
        setUser(null);
        setInited(true)
        localStorage.removeItem("token")

        window.location.replace("/")
    }

    const login = (user) => {
        setUser(user)
        setInited(true)

        window.location.replace("/profile")
    }

    const setUserData = (user) => {
        setUser(user)
        setInited(true)
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                logout,
                login,
                inited,
                setUserData
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};
export default AuthContextProvider;