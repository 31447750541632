import React from 'react';

const Card = ({ children, containerProps, className }) => {

    return (
        <div className={`card-container ` + className} {...containerProps}>
            {children}
        </div>
    );
}

export default Card;