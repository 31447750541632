import { useContext, useState } from "react";
import { MenuContext } from "../../utils/menuContext";
import { MouseContext } from "../../utils/mouseContext";
import { ThemeContext } from "../../utils/themeContext";
import { AiFillAndroid, AiFillApple, AiFillWindows, AiOutlineApple } from 'react-icons/ai'
import { ImEarth } from 'react-icons/im'

import MenuBlack from '../../assets/images/menu_black.svg'
import Menu from '../../assets/images/menu.svg'
import { AuthContext } from "../../utils/authContext";
import { useTranslation, withTranslation } from "react-i18next";
import { LangContext } from "../../utils/langContext";
import { useNavigate } from "react-router-dom";

const Header = ({ light = false }) => {
    const { t } = useTranslation()
    const { cursorChangeHandler } = useContext(MouseContext);
    const { toggle } = useContext(MenuContext)

    const navigate = useNavigate()

    const langContext = useContext(LangContext)

    const { user } = useContext(AuthContext)

    const [showPlatforms, setShowPlatforms] = useState(false)

    return (
        <>
            {/* <div className="header-silly-container">
                <p className="header-silly-text">
                    Let's welcome the new year together with <span className="primary">Silly Dragon</span>. <span onClick={() => {
                        window.open("https://dragon.matreshkavpn.com")
                    }} className="link">Learn More</span>
                </p>
            </div> */}

            <div className={`header-container ${light && "light"}`}>
                <div className="header-block">
                    <div className="header-left-container">
                        <a href='/'>
                            <img onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} width={200} src={(light ? require("../../assets/images/logo_big_dark.svg") : require("../../assets/images/logo_big.svg")).default} className="header-left-logo" />
                        </a>
                    </div>

                    <div className="header-right-container">
                        <img onMouseEnter={() => cursorChangeHandler("hover")}
                            onMouseLeave={() => cursorChangeHandler("")} width={38} onClick={toggle} height={38} src={light ? MenuBlack : Menu} className="header-right-menu" />

                        <div className="header-right-route-list">
                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='/' className="header-right-route">
                                {t("navigation_route_1")}
                            </a>

                            {/* <div className="header-right-platforms-container"> */}
                            <a onMouseEnter={() => setShowPlatforms(true)}
                                onMouseLeave={() => setShowPlatforms(false)} href='#' className="header-right-route platforms">
                                {t("navigation_route_2")}

                                <div className={`header-right-platforms-container ${showPlatforms ? "show" : ""}`}>
                                    <div className="header-right-platofrms-list-container">
                                        <a href="https://apps.apple.com/id/app/matreshka-vpn/id1622803257" className="header-right-platofrm-item">
                                            <AiFillApple style={{ marginRight: 10 }} size={23} /> iOS
                                        </a>

                                        <a href="/download/mac" className="header-right-platofrm-item">
                                            <AiFillApple style={{ marginRight: 10 }} size={23} /> MacOS
                                        </a>

                                        {/* <a className="header-right-platofrm-item">
                                        <AiFillApple style={{ marginRight: 10 }} size={23} /> Apple TV
                                    </a> */}
                                    </div>

                                    <div className="header-right-platofrms-list-container">
                                        <a href="/download/android" className="header-right-platofrm-item">
                                            <AiFillAndroid style={{ marginRight: 10 }} size={23} /> Android
                                        </a>

                                        <a href="/download/android-tv" className="header-right-platofrm-item">
                                            <AiFillAndroid style={{ marginRight: 10 }} size={23} /> Android TV
                                        </a>
                                    </div>

                                    <div className="header-right-platofrms-list-container">
                                        <a href="/download/windows" className="header-right-platofrm-item">
                                            <AiFillWindows style={{ marginRight: 10 }} size={23} /> Windows
                                        </a>
                                    </div>
                                </div>
                            </a>
                            {/* </div> */}

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='/about' className="header-right-route">
                                {t("navigation_route_3")}
                            </a>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='/subscription' className="header-right-route">
                                {t("navigation_route_4")}
                            </a>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='https://matreshkavpn.com/support' className="header-right-route">
                                {t("navigation_route_5")}
                            </a>

                            <a onMouseEnter={() => cursorChangeHandler("hover")}
                                onMouseLeave={() => cursorChangeHandler("")} href='https://t.me/matreshkavpn_official' className="header-right-route">
                                {t("navigation_route_6")}
                            </a>

                            {/* <div className="footer-language-selector-wrapper" style={{
                            marginLeft: 30
                        }}>
                            <div className="footer-language-selector-container" style={{
                                padding: "10px 30px"
                            }}>
                                <div className="footer-language-selector-title" style={{
                                    fontSize: 14
                                }}>
                                    {langContext.langs.find((item) => item.code == langContext.nowLang)?.title} <ImEarth style={{ marginLeft: 10 }} />
                                </div>

                                <select className="footer-language-selector-select" onChange={(e) => langContext.changeLang(e.currentTarget.value)}>
                                    {
                                        langContext.langs.map((item, idx) => (
                                            <option value={item.code}>{item.title}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;