import React, { useRef, useState, useEffect, useMemo } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, useFBX, useGLTF, useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useSpring, animated } from "@react-spring/three";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { DDSLoader } from 'three/examples/jsm/loaders/DDSLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { AMFLoader } from 'three/examples/jsm/loaders/AMFLoader'
import { useLoader } from '@react-three/fiber'
import { MathUtils, TextureLoader } from "three";
import { TweenMax } from "gsap/gsap-core";
import { SlowMo } from "gsap/all";
import gsap, { Sine } from "gsap";
THREE.DefaultLoadingManager.addHandler(/\.dds$/i, new DDSLoader());
// ref.current.position.x = Math.cos(window.scrollY / 600) * 1.9;


function SphereAnimated(props) {
  const three = useThree()

  // const mat = useLoader(TextureLoader, "line3.png");
  const obj = useLoader(OBJLoader, "test.obj", (obj) => {

  });

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const ref = useRef();

  useFrame(({ clock }) => {
    const time = clock.getElapsedTime();

    // ref.current.position.y = Math.cos(time) * 0.3;
    // ref.current.rotation.y += 0.003;
    // ref.current.rotation.x = Math.cos(time) * 0.2;
    // ref.current.position.x = Math.cos(window.scrollY / 600) * 1.9;
  });

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      // ref.current.position.x = MathUtils.randInt(1, 2)
      // ref.current.position.y = -window.scrollY / 250;
    })

    const globeMaterial = new THREE.MeshBasicMaterial({
      color: 0x828282,
      transparent: true,
      opacity: 0.3,
      wireframe: true,
      wireframeLinecap: 1000
    });

    ref.current.traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.material = globeMaterial;
      }
    });

    const anim = { camera: 4, opacity: -1, elem: 0, next: false, rotation: -3 };
    // TweenMax.to(ref.current.position, 1000, { x: 11, y: 222, ease: Sine.easeIn }).play();
    // gsap.to(ref.current.scale, { duration: 3, scaleX:2, yoyo: true, repeat: -1 })
    gsap.to(ref.current.rotation, { duration: 2, y: 6, x: .5, z: .3, yoyo: true, repeat: -1, ease: Sine.easeIn })
    gsap.to(ref.current.scale, { duration: 2, y: 14, x: 14, z: 14, yoyo: true, repeat: -1, ease: Sine.easeIn })
  }, [])


  return (
    <>
      <primitive material={null} scale={17} ref={ref} object={obj} />
    </>
  );
}

export default SphereAnimated;