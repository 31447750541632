import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button/Button';
import Card from '../../components/card/Card';
import BaseLayout from '../../components/layout/BaseLayout';
import { AuthContext } from '../../utils/authContext';
import request from '../../utils/request';

const Profile = () => {
    const [subscription, setSubscription] = useState(null)
    const [activeTab, setActiveTab] = useState(0)
    const [loadSubscriptionError, setLoadSubscriptionError] = useState(null)

    const switchTab = (tab) => setActiveTab(tab);

    const authData = useContext(AuthContext)

    const navigate = useNavigate();

    const loadSubscription = async () => {
        try {
            const result = await request.get("/api/v1/subscription/")

            setSubscription(result.data)
        } catch (e) {
            setLoadSubscriptionError("Нет")
            console.log(e)
        }
    }

    useEffect(() => {
        if (!authData.user && authData.inited)
            navigate("/login")
        else
            loadSubscription()
    }, [authData])

    return (
        <>
            <Helmet>
                <title>Профиль - MatreshkaVPN</title>
            </Helmet>
            <BaseLayout>
                <div className="profile-page-container">
                    <Card className="profile-card">
                        <div className="profile-card-header-container">
                            <p className="profile-card-header">
                                Профиль {authData?.user?.email}
                            </p>

                            <Button onClick={authData.logout}>
                                Выйти
                            </Button>
                        </div>

                        <div className="profile-card-body-container">
                            <div className="profile-card-navigation-container">
                                <div onClick={() => switchTab(0)} className="profile-card-navigation-item-container">
                                    <span className={`profile-card-navigation-item-indicator ${activeTab == 0 && "active"}`} />

                                    <p className={`profile-card-navigation-item ${activeTab == 0 && "active"}`}>
                                        Информация
                                    </p>
                                </div>

                                <div onClick={() => navigate("/subscription")} className="profile-card-navigation-item-container">
                                    <span className={`profile-card-navigation-item-indicator ${activeTab == 1 && "active"}`} />

                                    <p className={`profile-card-navigation-item ${activeTab == 1 && "active"}`}>
                                        Подписка
                                    </p>
                                </div>

                                <div onClick={() => switchTab(2)} className="profile-card-navigation-item-container">
                                    <span className={`profile-card-navigation-item-indicator ${activeTab == 2 && "active"}`} />

                                    <p className={`profile-card-navigation-item ${activeTab == 2 && "active"}`}>
                                        Настройки
                                    </p>
                                </div>
                            </div>

                            <div className="profile-card-content-container">
                                <div className={`profile-card-content-settings-container ${activeTab == 2 && "show"}`}>
                                    <div className="profile-card-content-settings-item-container">
                                        <p className="profile-card-content-settings-item-name">
                                            Отключение подписки
                                        </p>

                                        <Button>
                                            Отключить
                                        </Button>
                                    </div>

                                    <div className="profile-card-content-settings-item-container">
                                        <p className="profile-card-content-settings-item-name">
                                            Удаление аккаунта
                                        </p>

                                        <Button>
                                            Удалить
                                        </Button>
                                    </div>

                                    <div className="profile-card-content-settings-last-enter-container">
                                        <p className="profile-card-content-settings-last-enter-header">
                                            Последний вход в аккаунт
                                        </p>

                                        <p className="profile-card-content-settings-last-enter-time">
                                            Время: 12.01.2022
                                        </p>

                                        <p className="profile-card-content-settings-last-enter-device">
                                            Устройство: Web-интерфейс
                                        </p>
                                    </div>
                                </div>

                                <div className={`profile-card-content-info-container ${activeTab == 0 && "show"}`}>
                                    <div className="profile-card-content-info-row-container">
                                        <p className="profile-card-content-info-row-name">
                                            Почта
                                        </p>

                                        <p className="profile-card-content-info-row-value">
                                            {authData?.user?.email}
                                        </p>
                                    </div>

                                    <div className="profile-card-content-info-row-container">
                                        <p className="profile-card-content-info-row-name">
                                            Подписка
                                        </p>

                                        <p className="profile-card-content-info-row-value">
                                            {
                                                moment(authData?.user?.createdAt).diff(moment(), "days") < -3 ?
                                                    loadSubscriptionError ? loadSubscriptionError : subscription?.name
                                                    : (
                                                        "Пробный период"
                                                    )
                                            }
                                        </p>
                                    </div>

                                    <div className="profile-card-content-info-row-container">
                                        <p className="profile-card-content-info-row-name">
                                            Дата окончания подписки
                                        </p>

                                        <p className="profile-card-content-info-row-value">
                                            {
                                                moment(authData?.user?.createdAt).diff(moment(), "days") < -3 ?
                                                    loadSubscriptionError ? loadSubscriptionError : moment(subscription?.expiredAt).format("DD.MM.YYYY")
                                                    : (
                                                        moment(authData?.user?.createdAt).add(3, "days").format("DD.MM.YYYY")
                                                    )
                                            }
                                        </p>
                                    </div>

                                    <div className="profile-card-content-info-row-container">
                                        <p className="profile-card-content-info-row-name">
                                            Дата создания аккаунта
                                        </p>

                                        <p className="profile-card-content-info-row-value">
                                            {moment(authData?.user?.createdAt).format("DD.MM.YYYY")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </BaseLayout>
        </>
    );
}

export default Profile;