import BaseLayout from "../../components/layout/BaseLayout";
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";

const Oferta = () => {
    const { t } = useTranslation()

    return (
        <>
            <Helmet>
                <title>Оферта - MatreshkaVPN</title>
            </Helmet>
            <BaseLayout light={true}>
                <div className="oferta-container">
                    <p className="oferta-content">
                        {parse(t("oferta"))}
                    </p>
                </div>
            </BaseLayout>
        </>
    );
}

export default Oferta;