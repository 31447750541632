import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import Input from "../../components/input/Input";
import BaseLayout from "../../components/layout/BaseLayout";
import TextArea from "../../components/textarea/TextArea";
import { ThemeContext } from "../../utils/themeContext";
import { Helmet } from 'react-helmet'
const NotFound = () => {

    const { switchTheme } = useContext(ThemeContext)

    useEffect(() => {
        switchTheme(true)
    }, [])

    const navigate = useNavigate()

    return (
        <>
            <Helmet>
                <title>Не найдено - MatreshkaVPN</title>
            </Helmet>
            <BaseLayout
                light={true}
            >
                <div className="not-found-block-container">
                    <p className="not-found-header">
                        404
                    </p>

                    <p className="not-found-subheader">
                        К сожалению, мы не смогли найти страницу по вашему запросу.
                    </p>

                    <div className="not-found-action-container">
                        <Button onClick={() => navigate("/")}>
                            На главную
                        </Button>
                    </div>
                </div>
            </BaseLayout>
        </>
    );
}

export default NotFound;